@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300&family=Poppins:wght@200;300;400;600;800&display=swap');

:root {
    --primary-dark: #285430;
    --primary-light: #5F8D4E;
}

@layer base {
    html {
        @apply scroll-smooth;
    }

    body {
        @apply font-[Poppins];
    }

    li {
        @apply p-4 text-sm;
    }

    button {
        @apply px-4 py-2 border bg-[#2a6c36] text-white hover:scale-105 duration-150 ease-in-out;
    }

    h2 {
        @apply text-xl font-bold;
    }

}

body {
    /*background: rgba(0, 0, 0, .3);*/
    /* overflow-x: hidden; */
}

@media screen and (min-width: 290px) and (max-width: 359px) {
    .tagBar {
        width: 100%;
        margin: auto;
    }
    .titleLogo {
        max-width: 78%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .logoImageDiv {
        width: 18%;
    }
    .logoImageDiv .logoImage {
        width: 100%;
        object-fit: cover;
    }
    .titleName {
        width: 80%;
        font-size: 90%;
        text-align: center;
    }
    .emailName {
        width: 18%;
        font-size: 80%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    /* Navbar */
    .navbarHeight {
        width: 100%;
        height: 30px;
        margin: auto;
    }
    .listName {
        font-size: 95%;
    }

    /* Hero Page */
    .section {
        width: 100%;
        height: 10rem;
        margin: auto;
        margin-top: 1rem;
    }
    .section-center {
        width: 100%;
        max-height: 9rem;
    }

    /* About Page */
    .about1 {
        width: 100%;
        margin: auto;
        margin-top: 1rem;
        padding: 0;
    }
    .aboutDiv1 {
        width: 100%;
    }
    .aboutDiv2 {
        width: 100%;
    }
    .aboutDivPhoto {
        width: 60%;
    }
    .aboutInfoDiv {
        padding: 0 0.5rem;
    }
    .aboutInfoDivTitle {
        font-size: 100%;
    }
    .aboutInfoDivParagraph {
        font-size: 80%;
    }
    .aboutDivBtn {
        width: 100%;
        display: flex;
        justify-content: center;
        align-content: center;
        margin-top: 1rem;
    }
    .aboutDivBtn form {
        width: 100%;
        display: flex;
        justify-content: center;
        align-content: center;
    }
    .aboutDivBtn form * {
        padding: 0.5rem;
        width: 50%;
        font-size: 70%;
    }

    /* Products Page */
    .productsFirstDiv {
        width: 100%;
        height: 18rem;
        display: flex;
        justify-content: center;
        align-content: center;
        margin: auto;
        margin-top: 1rem;
        padding: auto;
    }
    .productsFirstDiv .productsTitle {
       width: 100%;
       padding-left: 0;
    }
    .productsTitle h2 {
       text-align: center;
    }

    .productsDivPurple {
       width: 100%;
       height: 100%;
    }
    
    .productsDivLeftArrow, .productsDivRightArrow {
        display: flex;
        justify-content: center;
        align-content: center;
        padding: 0.2rem;
    }
    .productsDivCard {
       width: 70%; 
       height: 100%;
       border: none;
    }
    .productsDivCardDiv {
       width: 100%;
       height: 100%;
    }
    .productsDivCardDiv img {
       width: 100%;
       object-fit: contain;
    }
    .productsDivCardDiv h1 {
       font-size: 90%;
    }
    .productsDivCardDivBtn button {
       border: none;
    }

    /* Tagband */
    .tagband {
       width: 100%;
       height: 5rem;
       margin: auto;
       margin-top: 1rem;
    }
    .tagbandSecondDiv h1 {
       font-size: 90%;
    }
    /* Contact Page */
    .contactDiv {
       width: 100%;
       margin-top: 1rem;
       display: flex;
       justify-content: center;
       align-items: center;
       flex-direction: center;
       margin: auto;
       margin-top: 1rem;
    }
    .contactDivTitle {
       margin-top: 1rem;
    }
    .contactDivTitle h1 {
       font-size: 100%;
    }
    .contactThirdDiv {
       width: 100%;
       display: flex;
       justify-content: center;
       align-items: center;
       flex-direction: center;
    }

    .contactThirdDiv form {
       width: 100%;
       display: flex;
       justify-content: center;
       align-items: center;
       flex-direction: center;
    }
    .contactThirdDiv form * {
       width: 100%;
       display: flex;
       justify-content: center;
       align-items: center;
       flex-direction: center;
       font-size: 80%;
       border: none;
    }

   /* Links */
   .linksDiv {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      margin: auto;
      margin-top: 1rem;
    }
   .linksSecondDiv {
      width: 100%;
      height: 100%;
   }
   .linksThirdDiv {
      width: 100%;
   }
   .linksThirdDiv h2 {
      font-size: 100%;
      text-align: center;
      margin: auto;
    }
   .linksThirdDivUL li {
      margin: 0.5rem 0; 
      padding: 0;
   }
   
   /* Footer */
   .footer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
   }
   .footer p {
    width: 98%;
    font-size: 70%;
    text-align: center;
   }
}

.section-center article {
   background: rgba(0, 0, 0, 0.56);
   outline: solid 1px white;
}

article {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-transform: uppercase;
    opacity: 0;
    transition: all 1s ease-in-out;
}

article.activeSlide {
    opacity: 1;
    transform: translateX(0);
}

article.lastSlide {
    transform: translateX(-100%);
}

article.nextSlide {
    transform: translateX(100%);
}

/* for chrome */
.slider-container::-webkit-scrollbar {
    display: none;
}

/* for firefox and safari */
.slider-container {
   scrollbar-width: none;  
}

/* Animation */
.tagbandDiv img {
    animation: bounce 4s infinite;
}


@keyframes bounce {
  0%, 100% {
    transform: translateY(-7%);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
  50% {
    transform: translateY(7%);
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
  
}
